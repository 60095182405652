import React, { lazy, Suspense } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Shimmer1 } from "./components/shimmer";
import ScrollToTop from "./components/srollToTop";
import IsSuperAdmin from "./layout/isSuperAdmin";
import SuAdminDashboardAddCityImage from "./pages/suAdminDashboardAddCityImage";
import How from "./pages/AllSiteSettings/How.js";
import Tourtable from "./pages/AllSiteSettings/Tourtable";
const SuAdminDashboard = lazy(() => import("./pages/suAdminDashboard"));
const SuAdminDashboardTour = lazy(() => import("./pages/suAdminDashboardTour"));
const SuAdminDashboardActualization = lazy(() =>
  import("./pages/suAdminDashboardActualization")
);
const SuAdminDashboardPromotion = lazy(() =>
  import("./pages/suAdminDashboardPromotion")
);
const ProtectedRoute = lazy(() => import("./pages/protectedRoute"));
const RegistrationSuccess = lazy(() => import("./pages/registrationSuccess"));
const RegistrationEmailVerified = lazy(() =>
  import("./pages/registrationEmailVerified")
);
const AdminDashboardTourReviews = lazy(() =>
  import("./pages/adminDashboardTourReviews")
);
const DashboardLayout = lazy(() => import("./layout/dashboardLayout"));
const MainLayout = lazy(() => import("./layout/mainLayout"));
const AboutCreatingAudioGuide = lazy(() =>
  import("./pages/aboutCreatingAudioGuide")
);
const AboutWorldTourAudioGuide = lazy(() =>
  import("./pages/aboutWorldTourAudioGuide")
);
const AdminDashboardAnalytics = lazy(() =>
  import("./pages/adminDashboardAnalytics")
);
const AdminDashboard = lazy(() => import("./pages/adminDashboard"));
const TourDetailsList = lazy(() =>
  import("./pages/adminDashboard/tourDetailsList")
);
const AdminDashboardHelp = lazy(() => import("./pages/adminDashboardHelp"));
const CommunityGuidelines = lazy(() => import("./pages/communityGuidelines"));
const AdminDashboardCreateTour1 = lazy(() =>
  import("./pages/adminDashboardCreateTour1")
);
const AdminDashboardCreateTour2 = lazy(() =>
  import("./pages/adminDashboardCreateTour2")
);
const AdminDashboardCreateTour3 = lazy(() =>
  import("./pages/adminDashboardCreateTour3")
);
const AdminDashboardCreateTour4 = lazy(() =>
  import("./pages/adminDashboardCreateTour4")
);
const AdminDashboardCreateTour5 = lazy(() =>
  import("./pages/adminDashboardCreateTour5")
);
const AdminDashboardAddAnotherLang1 = lazy(() =>
  import("./pages/adminDashboardAddAnotherLang1")
);
const AdminDashboardAddAnotherLang2 = lazy(() =>
  import("./pages/adminDashboardAddAnotherLang2")
);
const AdminDashboardPromotion = lazy(() =>
  import("./pages/adminDashboardPromotion")
);
const AdminDashboardReviews = lazy(() =>
  import("./pages/adminDashboardReviews")
);
const AudioGuideCreatingSteps = lazy(() =>
  import("./pages/audioGuideCreatingSteps.js")
);
const DestinationSlug = lazy(() => import("./pages/destinationSlug"));
const DestinationSlugAbout = lazy(() => import("./pages/destinationSlugAbout"));
const Home = lazy(() => import("./pages/home"));
const Registration = lazy(() => import("./pages/registration"));
const SuAdminDashboardToursCities = lazy(() =>
  import("./pages/suAdminDashboardToursCities")
);
const SuAdminDashboardTours = lazy(() =>
  import("./pages/suAdminDashboardTours")
);
const SuAdminDashboardToursNewDraft = lazy(() =>
  import("./pages/suAdminDashboardToursNewDraft")
);
const SuAdminDashboardUsersCountries = lazy(() =>
  import("./pages/suAdminDashboardUsersCountries")
);
const SuAdminDashboardUsersCities = lazy(() =>
  import("./pages/suAdminDashboardUsersCities")
);
const SuAdminDashboardUsers = lazy(() =>
  import("./pages/suAdminDashboardUsers")
);
const SuAdminDashboardUserDetails = lazy(() =>
  import("./pages/suAdminDashboardUserDetails")
);
const SuAdminDashboardFreeWalksLists = lazy(() =>
  import("./pages/suAdminDashboardFreeWalksLists")
);
const SuAdminDashboardFreeWalksCountries = lazy(() =>
  import("./pages/suAdminDashboardFreeWalksCountries")
);
const SuAdminDashboardFreeWalksCities = lazy(() =>
  import("./pages/suAdminDashboardFreeWalksCities")
);
const SuAdminDashboardFreeWalks = lazy(() =>
  import("./pages/suAdminDashboardFreeWalks")
);
const CreateFreeWalkTrans = lazy(() =>
  import("./pages/suAdminDashboardFreeWalks/createFreeWalkTrans.js")
);
const UpdateFreeWalkTrans = lazy(() =>
  import("./pages/suAdminDashboardFreeWalks/updateFreeWalkTrans")
);
const NotFound = lazy(() => import("./pages/notFound"));
const Login = lazy(() => import("./pages/login"));

const TestingMapImage = lazy(() => import("./pages/testingMapImage/drawMap"));
const AdminPublishPage = lazy(() =>
  import("./pages/adminDashboard/suAdminDashboardPublished")
);
const FreeWalkCreate = lazy(() =>
  import("./pages/suAdminDashboardFreeWalks/createFreeWalk")
);
const FreeWalkUpdate = lazy(() =>
  import("./pages/suAdminDashboardFreeWalks/updateFreeWalk")
);
const LanguageCreate = lazy(() =>
  import("./pages/suAdminDashboardLanguages/createLanguage")
);
const LanguageUpdate = lazy(() =>
  import("./pages/suAdminDashboardLanguages/updateLanguage")
);
const LanguageLists = lazy(() =>
  import("./pages/suAdminDashboardLanguages")
);
const AdminSiteSetting = lazy(() => import("./pages/adminSiteSetting"));
const AdminSiteSettingAdd = lazy(() =>
  import("./pages/adminSiteSetting/addSiteSetting")
);
const AdminSiteSettingEdit = lazy(() =>
  import("./pages/adminSiteSetting/editSiteSetting")
);
const DownloadApps = lazy(() => import("./pages/AllSiteSettings/DownloadApps"));
const QRDownload = lazy(() => import("./pages/AllSiteSettings/QRDownload"));

const PrivacyPolicy = lazy(() => import("./pages/AllSiteSettings/Policy"));
const CookiePolicy = lazy(() => import("./pages/AllSiteSettings/Cookie"));
const TermsPolicy = lazy(() => import("./pages/AllSiteSettings/Terms"));
const Contact = lazy(() => import ("./pages/AllSiteSettings/Contact"))
function App() {
  return (
    <Router>
      <Suspense fallback={<Shimmer1 />}>
        <Routes>
          <Route element={<ScrollToTop />}>
            <Route element={<MainLayout />}>
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="download" element={<DownloadApps />} />
              <Route path="qr-download" element={<QRDownload />} />
              <Route path="terms" element={<TermsPolicy />} />
              <Route path="cookie-policy" element={<CookiePolicy />} />
              <Route path="contact" element={<Contact />} />
              <Route path="how" element={<How />} />
              <Route path="countries" element={<Tourtable />} />

              <Route path="/testing-map-image" element={<TestingMapImage />} />

              <Route path="/" element={<Home />} />
              <Route
                path="/world-tour-audio-guide/about"
                element={<AboutWorldTourAudioGuide />}
              />

              <Route
                path="/audio-guide-creating-about"
                element={<AboutCreatingAudioGuide />}
              />
              <Route
                path="/audio-guide-creating-steps"
                element={<AudioGuideCreatingSteps />}
              />
              <Route
                path="/community-guidelines"
                element={<CommunityGuidelines />}
              />

              <Route
                path="/destination/:destinationId"
                element={<DestinationSlug />}
              />
              <Route
                path="/destination/:destinationId/about/:id"
                element={<DestinationSlugAbout />}
              />

              <Route path="/login" element={<Login />} />

              <Route path="/registration" element={<Registration />} />
              <Route
                path="/registration/success"
                element={<RegistrationSuccess />}
              />
              <Route
                path="/registration/email-verified"
                element={<RegistrationEmailVerified />}
              />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route element={<DashboardLayout />}>
                <Route path="/dashboard" element={<AdminDashboard />} />
                <Route
                  path="/dashboard/tour/details/:tourId"
                  element={<TourDetailsList />}
                />
                <Route
                  path="/dashboard/analytics"
                  element={<AdminDashboardAnalytics />}
                />
                <Route
                  path="/dashboard/reviews"
                  element={<AdminDashboardReviews />}
                />
                <Route
                  path="/dashboard/promotion"
                  element={<AdminDashboardPromotion />}
                />
                <Route
                  path="/dashboard/help"
                  element={<AdminDashboardHelp />}
                />

                <Route
                  path="/dashboard/tours/:tourId/reviews"
                  element={<AdminDashboardTourReviews />}
                />

                <Route
                  path="/dashboard/tour/:tourId/create/overview"
                  element={<AdminDashboardCreateTour1 />}
                />
                <Route
                  path="/dashboard/tour/:tourId/create/stops"
                  element={<AdminDashboardCreateTour2 />}
                />
                <Route
                  path="/dashboard/tour/:tourId/create/reorder-maps"
                  element={<AdminDashboardCreateTour3 />}
                />
                <Route
                  path="/dashboard/tour/:tourId/create/upload-maps"
                  element={<AdminDashboardCreateTour4 />}
                />
                <Route
                  path="/dashboard/tour/:tourId/create/publish"
                  element={<AdminDashboardCreateTour5 />}
                />

                <Route
                  path="/dashboard/tour/:tourId/add-another-lang/overview"
                  element={<AdminDashboardAddAnotherLang1 />}
                />
                <Route
                  path="/dashboard/tour/:tourId/add-another-lang/stops"
                  element={<AdminDashboardAddAnotherLang2 />}
                />

                <Route element={<IsSuperAdmin />}>
                  <Route
                    path="/admin/dashboard"
                    element={<SuAdminDashboard />}
                  />
                  <Route
                    path="/admin/dashboard/tour"
                    element={<SuAdminDashboardTour />}
                  />
                  <Route
                    path="/admin/dashboard/actualization"
                    element={<SuAdminDashboardActualization />}
                  />
                  <Route
                    path="/admin/dashboard/published"
                    element={<AdminPublishPage />}
                  />
                  <Route
                    path="/admin/dashboard/promotion"
                    element={<SuAdminDashboardPromotion />}
                  />
                  <Route
                    path="/admin/dashboard/site-setting"
                    element={<AdminSiteSetting />}
                  />
                  <Route
                    path="/admin/dashboard/site-setting/add"
                    element={<AdminSiteSettingAdd />}
                  />
                  <Route
                    path="/admin/dashboard/site-setting/update/:id"
                    element={<AdminSiteSettingEdit />}
                  />
                  <Route
                    path="/admin/dashboard/new-draft"
                    element={<SuAdminDashboardToursNewDraft />}
                  />
                  <Route
                    path="/admin/dashboard/users/countries"
                    element={<SuAdminDashboardUsersCountries />}
                  />
                  <Route
                    path="/admin/dashboard/users/countries/:countryId/cities"
                    element={<SuAdminDashboardUsersCities />}
                  />
                  <Route
                    path="/admin/dashboard/users/countries/:countryId/cities/:cityId"
                    element={<SuAdminDashboardUsers />}
                  />
                  <Route
                    path="/admin/dashboard/users/:userId/details"
                    element={<SuAdminDashboardUserDetails />}
                  />
                  <Route
                    path="/admin/dashboard/tour/countries/:countryName/cities"
                    element={<SuAdminDashboardToursCities />}
                  />
                  <Route
                    path="/admin/dashboard/tour/countries/:countryName/cities/:cityName"
                    element={<SuAdminDashboardTours />}
                  />
                   {/* languages routes */}
                   <Route
                    path="/admin/dashboard/language/create"
                    element={<LanguageCreate />}
                  />
                  <Route
                    path="/admin/dashboard/language/update/:id"
                    element={<LanguageUpdate />}
                  />
                   <Route
                    path="/admin/dashboard/language/lists"
                    element={<LanguageLists />}
                  />
                  {/* language routes end */}
                  <Route
                    path="/admin/dashboard/free-walk/create"
                    element={<FreeWalkCreate />}
                  />
                  <Route
                    path="/admin/dashboard/free-walk/update/:id"
                    element={<FreeWalkUpdate />}
                  />
                   <Route
                    path="/admin/dashboard/free-walk/lists"
                    element={<SuAdminDashboardFreeWalksLists />}
                  />
                  <Route
                    path="/admin/dashboard/free-walk/countries"
                    element={<SuAdminDashboardFreeWalksCountries />}
                  />
                  <Route
                    path="/admin/dashboard/free-walk/countries/:countryId/cities"
                    element={<SuAdminDashboardFreeWalksCities />}
                  />
                  <Route
                    path="/admin/dashboard/free-walk/countries/:countryId/cities/:cityId"
                    element={<SuAdminDashboardFreeWalks />}
                  />
                  <Route
                    path="/admin/dashboard/free-walk-translation/:freeWalkId/add"
                    element={<CreateFreeWalkTrans />}
                  />
                  <Route
                    path="/admin/dashboard/free-walk-translation/:freeWalkTransId/update"
                    element={<UpdateFreeWalkTrans />}
                  />
                  <Route
                    path="/admin/dashboard/add-city-img"
                    element={<SuAdminDashboardAddCityImage />}
                  />
                  {/* policy */}
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
      <ToastContainer /> {/**instantiating global toaster-container component*/}
    </Router>
  );
}

export default App;
